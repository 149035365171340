/** app download icon design  */

/* Container Styles */
.appDownload {
    position: fixed;
    z-index: 99;
    top: 30%;
    right: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}
  
  /* Link Styles */
  .appDownload a {
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
  }
  
  /* Typography (Text) Styles */
  .appDownload a p {
    font-weight: bold;
    color: #333;
  }
  
  /* Icon Styles with Combined Animations */
  .appDownload a svg {
    font-size: 30px;
    color: red;
    font-weight: 600;
    animation: bounce 1s ease infinite, pulseGlow 1.5s ease-in-out infinite;
    transition: transform 0.3s ease;
  }
  
  /* Hover Effects */
  .appDownload:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .appDownload:hover a svg {
    transform: scale(1.2); /* Zooms slightly on hover */
  }
  
  /* Bounce Animation */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px); /* Moves up */
    }
  }
  
  /* Pulse + Glow Effect Animation */
  @keyframes pulseGlow {
    0% {
      transform: scale(1);
      filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
    }
    50% {
      transform: scale(1.2); /* Enlarges slightly */
      filter: drop-shadow(0 0 20px rgba(255, 0, 0, 0.9)); /* Glows more */
    }
    100% {
      transform: scale(1);
      filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5)); /* Returns to normal */
    }
  }
  